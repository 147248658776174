<template>
  <div class="d-flex flex-column flex-root vh-100">
    <div class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white" :class="{
      'login-signin-on': this.state == 'signin',
      'login-signup-on': this.state == 'signup',
      'login-forgot-on': this.state == 'forgot',
    }" id="kt_login">
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto" style="background-color: #110f38;">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <!-- <img src="media/logos/logo2.png" class="max-h-90px" alt=""/> -->
          </a>
          <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg mb-5" style="color: white;">
            CLINICAS UCP<br>
          </h3>
        </div>
        <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center"
          style="background-size: 60%; " :style="{ backgroundImage: `url(${backgroundImage})` }"></div>

      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form class="form" novalidate="novalidate" id="kt_login_signin_form" @submit.stop.prevent="onSubmitLogin()">
              <div class="pb-13 pt-lg-0 pt-5">
                <h3 class="font-weight-bolder text-center text-dark font-size-h4 font-size-h1-lg">
                  UCP
                </h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">Acceso</label>
                <div id="example-input-group-1" label="" label-for="example-input-1">
                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="login"
                    ref="login" v-model="form.login" />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5">Contraseña</label>
                </div>
                <div id="example-input-group-2" label="" label-for="example-input-2">
                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password"
                    name="password" ref="password" v-model="form.password" autocomplete="off" />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  :class="{ 'spinner spinner-light spinner-right': loading }" :disabled="loading">
                  Entrar
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      loading: false,
      form: {
        login: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    // backgroundImage() {
    //   return (
    //     process.env.BASE_URL + "media/svg/illustrations/logo7.png"
    //   );
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/logos/logo_novo.png"
      );
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        login: {
          validators: {
            notEmpty: {
              message: "Username is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    onSubmitLogin() {
      this.$store.dispatch(LOGOUT);
      this.loading = true;
      this.$store
        .dispatch(LOGIN, this.form)
        .then(() => this.$router.push({ name: "dashboard" }))
        .catch((error) => {
          if (error.response.status == 503) {

            Swal.fire({
              title: "AVISO",
              text: error.response.data.error,
              
            });
          } else {
            Swal.fire({
              title: "Datos incorrectos!",
              text: "Confirme sus datos e intente de nuevamente.",
              icon: "Error",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
